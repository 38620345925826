import React from 'react';
import '../styles/master.scss';
import '../styles/homepage.scss';

class Homepage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            value: 'default',
            error: null,
        }
    }
    render(){
        const classnames = "main-inner homepage"
        return(
            <div className={classnames}>
                <h1 className="hello"><span role="img" aria-label='waving hand emoji' className="wave">👋</span>Hi, We're <span className="highlight">ReverseHolo.</span></h1>
                <p>Catch ’em all, trade ’em all, and find your <span className="highlight">perfect match!</span></p>
                <p>We’re almost ready to launch the <span className="highlight">ultimate online destination</span> for Pokémon card collectors and enthusiasts. Whether you’re searching for rare cards, building the perfect deck, or looking to trade, our marketplace will connect you with the Pokémon community <span className="highlight">like never before.</span></p>
                <h2>What to Expect:</h2>
                <ul>
                    <li>Wide Selection: From vintage classics to the latest releases, find every card you need.</li>
                    <li>Trusted Community: Buy, sell, and trade with verified collectors.</li>
                    <li>Exclusive Features: Wishlist tracking, custom collections, and much more!</li>
                </ul>
                <p>✨ Sign up now to be the first to know when we launch! ✨</p>
                <input type="text" placeholder="Email address" name="mail" required />
                {this.state.error !== null && <p>I am logged in</p> }
            </div>
        )
    }
}

export default Homepage;
